import React from 'react'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import { useI18next, Link } from 'gatsby-plugin-react-i18next'
import { INLINES } from '@contentful/rich-text-types'
import LiteYouTubeEmbed from 'react-lite-youtube-embed'
import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css'
import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share'

import Layout from '../components/layout'
import { getLocaleFromLanguage } from '../utils'

export default function PageLanding({ data, previousPage, nextPage }) {
  const { t, language } = useI18next()
  const locale = getLocaleFromLanguage(language)
  const page = data?.allContentfulLandingPage.nodes.filter(
    (page) => page.node_locale === locale
  )[0]

  const options = {
    renderNode: {
      [INLINES.HYPERLINK]: (node) => {
        const uri = node.data.uri
        if (uri.indexOf('youtu.be') !== -1) {
          const id = uri.split('.be/').pop()
          return (
            <div className="px-2 flex flex-col items-center w-full">
              <LiteYouTubeEmbed id={id} title="" />
            </div>
          )
        } else if (uri === 'https://m.me/amritvanimedia') {
          return (
            <a
              href={uri}
              target="_blank"
              rel="noopener noreferrer"
              className="no-underline leading-normal"
            >
              <div className="rounded bg-gradient-to-br from-sky-600 to-violet-900 text-violet-100 px-6 py-3 mx-4 md:mx-auto md:w-max">
                {node.content[0].value}
              </div>
            </a>
          )
        } else
          return (
            <a href={uri} target="_blank" rel="noopener noreferrer">
              {node.content[0].value}
            </a>
          )
      },
    },
  }
  return (
    <Layout>
      <div className="">
        <GatsbyImage
          image={page.heroImage?.gatsbyImageData}
          className="w-full max-h-[50vh] min-h-[30vh]"
        />
        <article className="prose mx-auto px-2 mt-8">
          <h1>{page.pageHeading}</h1>
          <div className="">
            {page.pageContent?.raw && renderRichText(page.pageContent, options)}
          </div>
          <div className="flex justify-around items-center">
            <Link to="/bible">{t('getABible')}</Link>
            <div className="flex items-center gap-2">
              <p className="font-bold pr-2">{t('shareThis')}:</p>
              <FacebookShareButton
                url={typeof window !== 'undefined' ? window.location.href : ''}
              >
                <FacebookIcon size={32} round />
              </FacebookShareButton>
              <WhatsappShareButton
                url={typeof window !== 'undefined' ? window.location.href : ''}
              >
                <WhatsappIcon size={32} round />
              </WhatsappShareButton>
              <EmailShareButton
                url={typeof window !== 'undefined' ? window.location.href : ''}
              >
                <EmailIcon size={32} round />
              </EmailShareButton>
            </div>
          </div>
        </article>
        {(previousPage || nextPage) && (
          <nav>
            <ul className="">
              {previousPage && (
                <li>
                  <Link to={`/resources/${previousPage.slug}`} rel="prev">
                    ← {previousPage.title}
                  </Link>
                </li>
              )}
              {nextPage && (
                <li>
                  <Link to={`/resources/${nextPage.slug}`} rel="next">
                    {nextPage.title} →
                  </Link>
                </li>
              )}
            </ul>
          </nav>
        )}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query landingPage($slug: String!, $language: String!) {
    allContentfulLandingPage(filter: { slug: { eq: $slug } }) {
      nodes {
        node_locale
        slug
        pageHeading
        pageContent {
          raw
        }
        heroImage {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
    }
    locales: allLocale(
      filter: { ns: { in: ["common", "topics"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
